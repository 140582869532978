import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.scss"
import payLogo from "../images/partners-horizontal.svg"
import avstoreLogo from "../images/AVstore.png"
const Layout = ({ children, index }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="layout__container--main">
      <div className="layout-dark-bg"></div>
      <div className="highlight-bg"></div>
      <div className="highlight-bg offseted"></div>
      <div
        className="layoutContainer container"
      >
      <Header siteTitle={data.site.siteMetadata.title} />
      {index?
        <>
          <h1 className="mainHeader">Experience easy and secure online payments to win </h1>
          <h6 className="subHeader">How does it sound to you?</h6>
        </>: 
        <>
          {/* <h1 className="mainHeader is-hidden-desktop-only is-hidden-tablet-only is-hidden-mobile">Experience easy and secure online payments to win </h1>
          <h6 className="subHeader is-hidden-desktop-only is-hidden-tablet-only is-hidden-mobile">How does it sound to you?</h6> */}
        </>
      }
        <main className={index ? null : 'more-space'}>{children}</main>
        <footer className="main">
          <div className="partners">
            <span>Secure payments with</span>
            <img src={payLogo} alt="Partners Horizontal"/>
          </div>
          {/* <div className="sponsor">
            <span>Shopping experince sponsored by our partner</span>
            <img src={avstoreLogo} alt="Partners Horizontal"/>
          </div> */}
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
